<template>
  <div class="page-container">
    <!-- header -->
    <!-- body -->
    <el-card>
      <el-table
        max-height="700"
        :data="dormFloorTree"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="label" label="楼栋">
        </el-table-column>
        <el-table-column prop="bedNum" :label="'可住人数：'+countInfo.bedNum">
        </el-table-column>
        <el-table-column prop="noEmptyBedNum" :label="'已入住人数：'+countInfo.noEmptyBedNum"></el-table-column>
        <el-table-column prop="emptyBedNum" :label="'空床位数：'+countInfo.emptyBedNum"></el-table-column>
        <el-table-column prop="emptyBedName" label="空床位情况"></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dormFloorTree: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      countInfo: {
        bedNum: 0,
        noEmptyBedNum: 0,
        emptyBedNum: 0
      }
    }
  },
  created () {
    this.getDormTree()
  },
  methods: {
    // 获取楼层树
    getDormTree () {
      this.$http.getDormTree({}).then((res) => {
        this.dormFloorTree = res.data
        this.numberCountHandle(this.dormFloorTree)
      })
    },
    // 数量统计
    numberCountHandle (list) {
      const l1 = list.map((item) => item.bedNum)
      const l2 = list.map((item) => item.noEmptyBedNum)
      const l3 = list.map((item) => item.emptyBedNum)
      const bedNum = l1.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const noEmptyBedNum = l2.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const emptyBedNum = l3.reduce((prev, current, index, arr) => {
        return prev + current
      })
      this.countInfo = {
        bedNum: bedNum || 0,
        noEmptyBedNum: noEmptyBedNum || 0,
        emptyBedNum: emptyBedNum || 0
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
